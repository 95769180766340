export const Questions1 = [
  {
    "Name": "1",
    "Title": "Estou me sentindo INTERESSADO."
  },
  {
    "Name": "2",
    "Title": "Estou me sentindo PERTURBADO."
  },
  {
    "Name": "3",
    "Title": "Estou me sentindo EXCITADO."
  },
  {
    "Name": "4",
    "Title": "Estou me sentindo ATORMENTADO."
  },
  {
    "Name": "5",
    "Title": "Estou me sentindo AGRADAVELMENTE SURPREENDIDO."
  },
  {
    "Name": "6",
    "Title": "Estou me sentindo CULPADO."
  },
  {
    "Name": "7",
    "Title": "Estou me sentindo ASSUSTADO."
  },

  {
    "Name": "8",
    "Title": "Estou me sentindo CALOROSO."
  },

  {
    "Name": "9",
    "Title": "Estou sentindo REPULSA."
  },

  {
    "Name": "10",
    "Title": "Estou me sentindo ENTUSIASMADO."
  }
];

export const Questions2 = [
  {
    "Name": "11",
    "Title": "Estou me sentindo ORGULHOSO."
  },

  {
    "Name": "12",
    "Title": "Estou me sentindo IRRITADO."
  },

  {
    "Name": "13",
    "Title": "Estou me sentindo ENCANTADO"
  },
  {
    "Name": "14",
    "Title": "Estou sentindo REMORSO."
  },
  {
    "Name": "15",
    "Title": "Estou me sentindo INSPIRADO."
  },
  {
    "Name": "16",
    "Title": "Estou me sentindo NERVOSO."
  },
  {
    "Name": "17",
    "Title": "Estou me sentindo DETERMINADO."
  },
  {
    "Name": "18",
    "Title": "Estou me sentindo TRÊMULO."
  },
  {
    "Name": "19",
    "Title": "Estou me sentindo ATIVO."
  },
  {
    "Name": "20",
    "Title": "Estou me sentindo AMEDRONTADO."
  }
];