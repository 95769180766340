export const Questions1 = [
    {
      "Name": "1",
      "Title": "Apavorado"
    },
  {
      "Name": "2",
      "Title": "Animado"
    },
  {
      "Name": "3",
      "Title": "Confuso"
    },
  {
      "Name": "4",
      "Title": "Esgotado"
    },
  {
      "Name": "5",
      "Title": "Deprimido"
    },
  {
      "Name": "6",
      "Title": "Desanimado"
    },
  {
      "Name": "7",
      "Title": "Irritado"
    },
  {
      "Name": "8",
      "Title": "Exausto"
    }
  ];
  
  export const Questions2 = [
    {
      "Name": "9",
      "Title": "Inseguro"
    },
  {
      "Name": "10",
      "Title": "Sonolento"
    },
    {
      "Name": "11",
      "Title": "Zangado"
    },
    {
      "Name": "12",
      "Title": "Triste"
    },
    {
      "Name": "13",
      "Title": "Ansioso"
    },
    {
      "Name": "14",
      "Title": "Preocupado"
    },
    {
      "Name": "15",
      "Title": "Com disposição"
    },
    {
      "Name": "16",
      "Title": "Infeliz"
    }
    
  ];
  
  export const Questions3 = [
    {
      "Name": "17",
      "Title": "Desorientado"
    },
    {
      "Name": "18",
      "Title": "Tenso"
    },
    {
      "Name": "19",
      "Title": "Com raiva"
    },
    {
      "Name": "20",
      "Title": "Com energia"
    },
    {
      "Name": "21",
      "Title": "Cansado"
    },
    {
      "Name": "22",
      "Title": "Mal-humorado"
    },
    {
      "Name": "23",
      "Title": "Alerta"
    },
    {
      "Name": "24",
      "Title": "Indeciso"
    }
  ];
    
  